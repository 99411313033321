<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  props: {
    msg: String,
  },
  components: {},
  computed: {},
  data() {
    return {






































    };
  },
  methods: {
    refreshData() {},
    closeClick() {
      this.$router.push("/");
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style>
#loginButton {
  margin-right: 100px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  margin-top: 30px;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
  font-family: "DaxlinePro-Light";
  src: url('assets/fonts/DaxlinePro-Light.otf');
}

@font-face {
  font-family: "DaxlinePro-Bold";
  src: url('assets/fonts/DaxlinePro-Bold.otf');
}

@font-face {
    font-family: 'SourceSansPro-Light';
    src: url('assets/fonts/SourceSansPro-Light.otf')  format('truetype'); 
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('assets/fonts/SourceSansPro-Semibold.otf')  format('truetype'); 
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('assets/fonts/SourceSansPro-Bold.otf')  format('truetype'); 
}
</style>
